import React from 'react';
import { useSelector } from 'react-redux';
import BookDescriptionCard from './bookDescriptionCard';
import BookChapterCard from './bookChapterCard';
import BookMediaCard from './bookMediaCard';
import ExpandableContainer from '../expandableContainer';
import './style.scss';

const MainContainer = (props) => {
  const { book, bookEng } = props;
  const { currentTrackId } = useSelector((state) => state.audio);
  const { show, interrupt } = useSelector((state) => state.miniPlayer);
  const showMobilePlayer = useSelector((state) => state.mobilePlayer.show);

  return (
    <ExpandableContainer expand={(show && !interrupt) || showMobilePlayer} className="bg-grey">
      <div className="books-page-container bg-grey">
        <div className="books-a">
          <BookDescriptionCard book={book} />
          <BookChapterCard book={book} currentTrack={currentTrackId} bookEng={bookEng} />
        </div>
        <div className="books-b hide-mobile">
          <BookMediaCard book={book} />
        </div>
      </div>
    </ExpandableContainer>
  );
};

export default MainContainer;
