import React from 'react';
import AuthStateProvider from '../components/authStateProvider';
import Header from '../components/header/header';
import MainContainer from '../components/booksPage/mainContainer';
import Footer from '../components/footer';
import SEO from '../components/SEO';

export default function Books(props) {
  const { translatedUrl, book, book_eng } = props.pageContext;

  return (
    <AuthStateProvider authRequired {...props}>
      <SEO title={`${book.title}`} />
      <Header show={true} translatedUrl={translatedUrl} />
      <MainContainer book={book} bookEng={book_eng} />
      <Footer variant="post-login-footer" />
    </AuthStateProvider>
  );
}
