import React from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Link } from 'gatsby';

const BookChapterCard = (props) => {
  const { book, currentTrack, bookEng } = props;
  const { language } = useSelector((state) => state);

  return (
    <div className="book-chapter-container">
      <div className="p-3">
        <p className="fnt-evolventa bold book-activity-title fnt-black">Activity Section</p>
      </div>
      <div className="book-chapters pl-3 pr-3">
        {book.chapters.map((chapter, index) => {
          const title = bookEng ? bookEng.chapters[index].title : chapter.title;
          const chapterSlug = title.toLowerCase().replace(/\s+/g, '-');

          return (
            <div
              key={`book-chapter-${index}`}
              className={isEqual(currentTrack, chapter.id) ? 'chapter-list-selected-div' : 'chapter-list-div'}
            >
              <Link
                to={`${language === 'eng' ? '' : '/es'}/${book.slug}/${chapterSlug}/`}
                className="chapter-list-title"
              >
                <span className="mb-0 fnt-poppins fnt-r-16 chapter-list-title">{`${index + 1}. ${chapter.title}`}</span>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookChapterCard;
