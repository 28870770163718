import React from 'react';

const BookDescriptionCard = (props) => {
  const { book } = props;

  return (
    <div className="book-description-container">
      <div className="book-cover p-3">
        <div className="border-rd-16">
          <img src={book.cover} alt="" className="book-card-img" />
        </div>
      </div>
      <div className="p-3 dsp-flx flx-col justify-center">
        <p className="fnt-evolventa book-title-text fnt-black bold">{book.title}</p>
        <p className="fnt-poppins book-description fnt-secondary">{book.description}</p>
      </div>
    </div>
  );
};

export default BookDescriptionCard;
